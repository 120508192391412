import {ImageResizeOptions, ImageHoverEffectOptions, WowImage} from 'wix-ui-tpa'
import React from 'react'
import {hasImage} from '@wix/wix-events-commons-statics'
import classNames from 'classnames'
import {resolveFocalPoint} from '../../../../../commons/utils/image'
import s from './event-image.scss'
import {classes} from './event-image.st.css'
import {EventImageProps} from './interfaces'

export const EventImage = ({
  event,
  position,
  fitImage,
  zoom,
  singleLayout,
  noBackground,
  backgroundFallback,
  opacityFallback,
}: EventImageProps) => {
  const focalPoint = resolveFocalPoint(position)

  const getOpacityClass = () => {
    if (singleLayout && opacityFallback) {
      return classes.singleOpacity
    } else {
      if (opacityFallback) {
        return classes.multiOpacityWithFallback
      } else {
        return classes.multiOpacity
      }
    }
  }

  const getBackgroundClass = () => {
    if (singleLayout) {
      return s.singleBackground
    } else if (backgroundFallback) {
      return s.multiBackgroundWithFallback
    } else {
      return s.multiBackground
    }
  }

  return (
    <>
      {!noBackground ? (
        <div className={classNames(s.container, getBackgroundClass())} data-hook="image-background" />
      ) : null}
      {hasImage(event) ? (
        <div className={s.container}>
          <WowImage
            className={getOpacityClass()}
            src={event.mainImage?.id}
            alt={event.title}
            sourceWidth={event.mainImage.width}
            sourceHeight={event.mainImage.height}
            focalPoint={focalPoint}
            resize={fitImage ? ImageResizeOptions.contain : ImageResizeOptions.cover}
            // loadingBehavior={ImageLoadingBehaviorOptions.blur}
            hoverEffect={zoom ? ImageHoverEffectOptions.zoom : undefined}
            fluid
          />
        </div>
      ) : null}
    </>
  )
}
